/**************************************************
 * Nombre:       Logo Titulo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Font, Image, StyleSheet, Text, View} from '@react-pdf/renderer';
import logo from '../Recursos/logo.png'
import f400 from '../Recursos/Poppins-Regular.ttf'
import f500 from '../Recursos/Poppins-Medium.ttf'
import f600 from '../Recursos/Poppins-SemiBold.ttf'
import f700 from '../Recursos/Poppins-Bold.ttf'
import f800 from '../Recursos/Poppins-Black.ttf'
import {obtenerFechaDiaYear} from "../../../Utilidades/obtenerFechaDiaYear";
import {obtenerFecha} from "../../../Utilidades/obtenerFecha";


const Logo_Titulo = ({datos, orden}) => {
    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: f400,
                fontWeight: 400
            },
            {
                src: f500,
                fontWeight: 500
            },
            {
                src: f600,
                fontWeight: 600
            },
            {
                src: f700,
                fontWeight: 700
            },
            {
                src: f800,
                fontWeight: 800
            },
        ]
    })


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff',
            paddingTop: 32,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 40
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
        },
        gitf: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            alignContent: 'flex-end',
        },
        tTitulo: {
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 700,
            color: '#0C4F80'
        },
        tMed: {
            fontSize: 8,
            fontFamily: 'Poppins',
            fontWeight: 400,
        }


    });


    return <View style={styles.git}>
        <View style={{...styles.git, width: '61%'}}>
            <Image src={logo} style={{width: 280, height: 50}}/>
        </View>

        <View style={{...styles.gitf, width: '39%'}}>
            <View style={{...styles.gitc, alignContent: 'flex-end'}}>
                <View style={styles.gitf}>
                    <Text
                        style={{
                            ...styles.tTitulo,
                            textAlign: 'right',
                            width: '100%',
                            lineHeight: 1.1
                        }}>{orden ? 'ORDEN DE SERVICIO' : 'COTIZACIÓN'}</Text>
                </View>

                <View style={{...styles.gitf, marginTop: 4}}>
                    <View style={{...styles.git, paddingLeft: orden ? 0 : 67}}>
                        <View style={{...styles.git, width: '50%', paddingLeft: 12}}>
                            <Text
                                style={{...styles.tMed, textAlign: 'left', width: '100%'}}>NÚMERO:</Text>
                        </View>
                        <View style={{...styles.git, width: '50%'}}>
                            <Text
                                style={{
                                    ...styles.tMed,
                                    textAlign: 'right',
                                    width: '100%'
                                }}>{datos.consecutivo}</Text>
                        </View>

                    </View>

                </View>

                <View style={{...styles.gitf, marginTop: 0}}>
                    <View style={{...styles.git, paddingLeft: orden ? 0 : 67}}>
                        <View style={{...styles.git, width: '50%', paddingLeft: 12}}>
                            <Text
                                style={{...styles.tMed, textAlign: 'left', width: '100%'}}>FECHA:</Text>
                        </View>
                        <View style={{...styles.git, width: '50%'}}>
                            <Text
                                style={{
                                    ...styles.tMed,
                                    textAlign: 'right',
                                    width: '100%'
                                }}>{obtenerFecha(orden ? datos.fechaAprobada : datos.fecha)}</Text>
                        </View>

                    </View>

                </View>

            </View>
        </View>
    </View>
}

export default Logo_Titulo;
